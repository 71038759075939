import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
type CompsProps = {
    title: string
    children?: ReactNode
}
function HeaderTitle({ title, children }: CompsProps) {
    const { t } = useTranslation()
    return (
        <motion.div
            initial={{ y: -30, opacity: 0 }}
            exit={{
                y: 30,
                opacity: 0
            }}
            animate={{
                y: 0,
                opacity: 1
            }}
            className='flex items-center justify-between p-4 mb-3 border-b-2 max-sm:flex-col max-sm:gap-2 border-slate-300 dark:border-slate-800'>
            <motion.h2
                initial={{ y: -60, opacity: 0, rotate: 5 }}
                exit={{ y: -60, opacity: 0, rotate: 5 }}
                animate={{ y: 0, opacity: 1, rotate: 0 }}
                className="text-xl font-extrabold text-slate-600 dark:text-slate-100">
                {t(title)}
            </motion.h2>
            {children}
        </motion.div>
    )
}

export default HeaderTitle