import { useTranslation } from "react-i18next"

function ErrorLoadData() {
    const { t } = useTranslation()
    return (
        <div className='flex items-center justify-center p-4 h-60'>
            <div className="flex flex-col items-center gap-0">
                <img src="/warning_6017351.png" className="w-20 mb-4" alt="" />
                <h1 className="text-lg font-extrabold">{t("An unexpected error occurred")}</h1>
                <p className="opacity-65">{t("An error occurred while fetching data. Please try again")}</p>
            </div>
        </div>
    )
}

export default ErrorLoadData