import HomeNav from './layout/HomeNav'
import Hero from './Home/Hero'
import Categories from './Home/Categories'
import Footer from './layout/Footer'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

type Props = {
    lang?: string
}
function IndexComponent({ lang = 'en' }: Props) {
    const { t } = useTranslation();
    return (
        <div dir={lang == "ar" ? "rtl" : "ltr"} className="pt-16 bg-white dark:bg-slate-900">
            <Helmet>
                <title>{t("Welcome to Help Center")} | CAFYB</title>
                <meta name="description" content="A travers cette documentation, vous apprendrez à utiliser aisément le module gestion commerciale de l'ERP CAFYB, de son paramétrage jusqu'à son utilisation quotidienne..." />
                <meta name="keywords" content="Help Center, cafyb, crm, erp, documentation" />

                <meta name="theme-color" content="#de3434" />

                <meta property="og:site_name" content="CAFYB" />
                <meta property="og:locale" content="fr" />
                <meta property="og:locale:alternate" content="fr" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={`${t("Welcome to Help Center")} | CAFYB`} />
                <meta property="og:description" content="A travers cette documentation, vous apprendrez à utiliser aisément le module gestion commerciale de l'ERP CAFYB, de son paramétrage jusqu'à son utilisation quotidienne..." />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@CafybWebsite" />
                <meta name="twitter:creator" content="@CafybWebsite" />
                <meta name="twitter:title" content={`${t("Welcome to Help Center")} | CAFYB`} />
                <meta name="twitter:description" content="A travers cette documentation, vous apprendrez à utiliser aisément le module gestion commerciale de l'ERP CAFYB, de son paramétrage jusqu'à son utilisation quotidienne..." />

                <meta property="og:url" content="https://www.cafyb.info/" />
                <meta name="image" property="og:image" content="/seo.jpg" />
                <meta name="twitter:image:src" content="/seo.jpg" />
            </Helmet>
            <HomeNav />
            <Hero />
            <Categories />
            <Footer />
        </div>
    )
}

export default IndexComponent