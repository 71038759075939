import { GetCategoriesQuery } from "@/query/posts"
import AppPanel from "../AppPanel"
import { Icon } from "@iconify/react"
import { motion } from "framer-motion"
import { Loader } from "@mantine/core"
import ErrorLoadData from "../ErrorLoadData"
function Categories() {
    const { data: categoriesData, isLoading: CategoryLoading, isError } = GetCategoriesQuery()

    if (CategoryLoading) {
        return <div className='flex items-center justify-center p-4 h-60'><Loader size={44} /></div>
    }

    if (!categoriesData || isError)
        return <ErrorLoadData />
    const containerVariants = {
        hidden: (i: number) => ({
            opacity: 0,
            y: 70,
            transition: { type: 'spring', delay: 0.5 * i }
        }),
        visible: (i: number) => ({
            opacity: 1,
            y: 0,
            transition: { type: 'spring', delay: 0.1 * i }
        }),
        exit: {
            y: 70,
            transition: { ease: 'easeInOut' }
        }
    }
    return (
        <div className="container -mt-14">
            <div className="grid grid-cols-3 gap-5 max-sm:grid-cols-1">
                {categoriesData?.sort((a, b) => Number(a.order_count) - Number(b.order_count)).map((item, i) => {
                    if (item.id !== 1)
                        return (
                            <motion.a
                                variants={containerVariants}
                                initial="hidden"
                                animate="visible"
                                className="block"
                                whileHover={{ y: -12 }}
                                custom={i}
                                exit="exit"
                                href={item.slug == 'mises-a-jour-du-produit' ? 'https://www.cafyb.com/mise-a-jour/' : `/category/${item.slug}`}
                                key={item.id}>
                                <AppPanel>
                                    <div className="flex gap-2 p-7 h-44">
                                        <div className="w-16">
                                            <Icon className="w-12 h-12" icon={item?.icon_name} />
                                        </div>
                                        <div className="w-[calc(100%-64px)]">
                                            <h2 className="text-lg font-bold">{item.name}</h2>
                                            <p className="mt-4 text-base leading-6 opacity-75">
                                                {item.description.length > 80 ?
                                                    `${item.description.substring(0, 80)}...` : item.description
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </AppPanel>
                            </motion.a>
                        )
                })}
            </div>
        </div>
    )
}

export default Categories