import { axiosAPI } from '@/axios/axios'

// Get Posts info API `GET`
export const getPosts = async () => axiosAPI().get(`/posts`).then((res) => res.data);

// Get Categories info API `GET`
export const getCategories = async () => axiosAPI().get(`/categories?filter[orderby]=order_count&order=desc`).then((res) => res.data);

// Get Single Category info API `GET`
export const getCategoryDetails = async ({ slug }: { slug: string }) => axiosAPI().get(`/categories?slug=${slug}`).then((res) => res.data);

// Get Posts list by Category ID info API `GET`
export const getPostsListByCatID = async (filter: { id: number, page: number }) => axiosAPI().get(`/posts?categories=${filter.id}&page=${filter.page}&orderby=id&order=asc`).then((res) => res.data);

// Get Post Detail by slug info API `GET`
export const getPostDetails = async ({ slug }: { slug: string }) => axiosAPI().get(`/posts?slug=${slug}&_embed=wp:term`).then((res) => res.data);

// Get Search results info API `GET`
export const getSearchResults = async ({ query }: { query: string }) => axiosAPI().get(`/posts?search=${query}`).then((res) => res.data);
