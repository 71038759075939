import { useTranslation } from "react-i18next";

function Footer() {
    const { t } = useTranslation()
    const brand = 'CAFYB'
    const version = '5.4.1.1'
    const date = new Date();
    const year = date.getFullYear();
    return (
        <div className="flex items-center justify-center gap-2 py-8 mt-6 border-t-2 opacity-70 border-t-slate-300">
            <p className="flex items-center justify-center gap-2 text-sm font-medium">
                <a className="font-bold text-red-700" href="http://www.cafyb.com/">{brand}</a>
                <span>{version}</span> &copy; {t("Copyright")} {year}
            </p>
        </div>
    )
}

export default Footer