import HeaderTitle from '@/components/HeaderTitle'
import { GetCategoryDetailQuery, GetPostsListByCategoryIdQuery } from '@/query/posts'
import { ActionIcon, Loader, rem } from '@mantine/core'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ErrorLoadData from '@/components/ErrorLoadData';
import { useState } from 'react';
import { Icon } from '@iconify/react';

function App() {
    const params = useParams()
    const { t } = useTranslation()
    const [page, setPage] = useState(1)
    const { slug } = params
    const getCateDetails: any = GetCategoryDetailQuery({ slug: slug })
    const getTotalCount = !getCateDetails.isLoading ? Number(getCateDetails?.data[0]?.count) : 0
    const getCateID = !getCateDetails.isLoading && getCateDetails?.data[0]?.id
    const getPosts = GetPostsListByCategoryIdQuery({ id: getCateID, page })
    const isDisabledNext = (getTotalCount - 10) == Number(getPosts?.data?.length)

    if (getPosts.isError)
        return <ErrorLoadData />
    if (getPosts?.isLoading || getCateDetails.isLoading)
        return <div className='flex items-center justify-center p-4 h-60'><Loader size={44} /></div>
    if (getPosts?.data?.length === 0)
        return <div className='flex items-center justify-center p-4 text-lg font-bold h-60'>{t("Content not found")}</div>
    return (
        <>
            <Helmet>
                <title>{getCateDetails?.data[0]?.name} | CAFYB</title>
            </Helmet>
            <div className='p-4'>
                <HeaderTitle
                    title={getCateDetails?.data[0]?.name} />

                <ul className='pl-7'>
                    {getPosts?.data?.map((item) => (
                        <li className='text-lg font-medium border-b border-b-slate-200 last:border-r-0' key={item.id}>
                            <Link to={`/${item.slug}`} className='flex p-4 transition-all duration-300 hover:text-slate-950 dark:hover:text-slate-50 hover:pl-7'>
                                {item?.title?.rendered}
                            </Link>
                        </li>
                    ))}
                </ul>
                {getPosts?.data?.length > 0 && <div className='py-3 mt-5 border-t border-t-gray-200 dark:border-t-slate-600'>
                    <ActionIcon.Group>
                        <ActionIcon disabled={page == 1} onClick={() => setPage(page - 1)} variant="default" size="xl" aria-label="Likes">
                            <Icon icon={`fa6-solid:angle-left`} className='w-5 h-5' />
                        </ActionIcon>
                        <ActionIcon disabled={isDisabledNext} onClick={() => setPage(page + 1)} variant="default" size="xl" aria-label="Gallery">
                            <Icon icon={`fa6-solid:angle-right`} className='w-5 h-5' />
                        </ActionIcon>
                    </ActionIcon.Group>
                </div>
                }
                {getPosts?.data?.length === 0 && <div className='flex items-center justify-center text-lg h-60 opacity-70'>Il n'y a aucune question dans cette section</div>}
            </div>
        </>
    )
}

export default App