import { Icon } from "@iconify/react";
import { useState } from "react";
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
function Hero() {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const [query, setQuery] = useState("")

    const handleSubmit = (e) => {
        e.preventDefault()
        navigate(`/search/${query}`)
    }
    return (
        <div className="p-8 py-24 bg-cover pb-36" style={{ backgroundImage: `url('/hero.png')` }}>
            <div className="max-w-4xl mx-auto">
                <div className="flex flex-col items-center justify-center">
                    <motion.h3
                        transition={{
                            y: {
                                delay: 0.2,
                                type: "spring",
                                duration: 0.9,
                                damping: 4,
                                stiffness: 20,
                                restDelta: 0.001
                            },
                            opacity: {
                                delay: 0.1,
                                type: "spring",
                                duration: 0.9,
                                damping: 4,
                                stiffness: 20,
                                restDelta: 0.001
                            },
                        }}
                        initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} className="text-5xl font-black text-white max-sm:text-3xl">{t("Help Center CAFYB")}</motion.h3>
                    <motion.p
                        transition={{
                            y: {
                                delay: 0.5,
                                type: "spring",
                                duration: 0.9,
                                damping: 4,
                                stiffness: 20,
                                restDelta: 0.001
                            },
                            opacity: {
                                delay: 0.5,
                                type: "spring",
                                duration: 0.9,
                                damping: 4,
                                stiffness: 20,
                                restDelta: 0.001
                            },
                        }} initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} className="mt-3 text-2xl font-light text-center text-white max-sm:text-xl">{t("Through this documentation, you will learn how to easily use the commercial management module of the CAFYB ERP, from its configuration to its daily use.")}</motion.p>
                </div>
                <motion.div
                    transition={{
                        y: {
                            delay: 0.8,
                            type: "spring",
                            duration: 0.9,
                            damping: 4,
                            stiffness: 20,
                            restDelta: 0.001
                        },
                        opacity: {
                            delay: 0.8,
                            type: "spring",
                            duration: 0.9,
                            damping: 4,
                            stiffness: 20,
                            restDelta: 0.001
                        },
                    }} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} className="relative w-full mt-6">
                    <form className="block w-full" onSubmit={handleSubmit}>
                        <button className="absolute right-7 top-4 opacity-30 hover:opacity-90">
                            <Icon icon={'iconamoon:search'} className="w-7 h-7 max-sm:h-5 max-sm:w-5" />
                        </button>
                        <input onChange={(e) => setQuery(e.target.value)} type="text" placeholder={t("Search in Help Center")} className="flex items-center justify-center w-full p-4 px-8 text-lg transition-all duration-150 bg-white border rounded-full shadow-lg max-sm:text-base max-sm:p-3 border-slate-200 dark:border-slate-800 focus:border-slate-400 text-slate-900 hover:bg-slate-50 dark:bg-slate-700 dark:text-slate-100" />
                    </form>
                </motion.div>
            </div>
        </div>
    )
}

export default Hero