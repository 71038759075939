import { Link, Outlet, useLocation } from "react-router-dom"
import { AnimatePresence, motion } from 'framer-motion'
import { useState } from "react";
import { GetCategoriesQuery, GetPostsListByCategoryIdQuery } from "@/query/posts";

type CompsProps = {
    isShow: boolean
    setIsShow: (isShow: boolean) => void
    clickableItem?: string
    setClickableItem?: (clickableItem: string) => void
}
function SidebarMobile({ isShow, setIsShow, clickableItem, setClickableItem }: CompsProps) {
    const [activeItem, setActiveItem] = useState(0);
    const location = useLocation();
    const { data: categoriesData } = GetCategoriesQuery()
    const getPosts = GetPostsListByCategoryIdQuery({ id: activeItem, page: 1 })
    return (
        <>
            <AnimatePresence>
                {isShow &&
                    <motion.div initial={{ x: -320, opacity: 0 }} animate={{ x: 0, opacity: 1 }} exit={{ x: -320, opacity: 0 }} className={`h-full fixed transition-all duration-500 w-[320px] z-[98] top-16 bg-[#fafcff] dark:bg-[#141e36]`}>
                        <ul>
                            {categoriesData?.map((el) => {
                                if (el.id !== 1)
                                    return (
                                        <li key={el.id} className="relative">
                                            <Link
                                                onClick={() => activeItem === el.id ? setActiveItem(0) : setActiveItem(el.id)}
                                                className={`flex p-4 gap-2.5 relative font-semibold text-[13px] items-center hover:to-[#ebeef1] hover:from-[#f1f4f8] dark:hover:from-[#172637] dark:hover:to-[#203244] hover:text-[#2c3e50] bg-gradient-to-bl ${`/category/${el.slug}` === location.pathname ? "font-bold text-slate-950 to-[#ebeef1] from-[#f1f4f8]" : "text-[#2c3e50] dark:text-[#dae4ee]"}`}
                                                to={`/category/${el.slug}`}
                                            >
                                                {el.name}
                                                {el.slug === location.pathname && <div className="w-0 h-0 border-t-[10px] absolute right-0 border-t-transparent border-r-[10px] border-r-slate-200 dark:border-r-[#0f172a] border-b-[10px] border-b-transparent" />}
                                            </Link>
                                            <AnimatePresence>
                                                {activeItem == el.id && <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className={`bg-white dark:bg-slate-700`}>
                                                    <ul>
                                                        {getPosts?.data?.map((item) => (
                                                            <li key={item.id} className="relative border-b border-b-slate-200 dark:border-b-slate-700 last:border-b-0">
                                                                <Link to={`/${item.slug}`} onClick={() => setIsShow(false)} className={`flex ps-7 p-3 gap-2.5 relative text-[12px] items-center hover:opacity-100 hover:text-black ${`/${item.slug}` === location.pathname ? "font-bold text-black dark:text-white" : "text-slate-700 dark:text-slate-200 font-medium"}`}>
                                                                    {item?.title?.rendered}
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </motion.div>}
                                            </AnimatePresence>
                                        </li>
                                    )
                            }
                            )}
                        </ul>
                    </motion.div>
                }
            </AnimatePresence>
            <Outlet />
        </>
    )
}

export default SidebarMobile