import HeaderTitle from '@/components/HeaderTitle'
import { GetSearchResultsQuery } from '@/query/posts'
import { Loader } from '@mantine/core'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ErrorLoadData from '@/components/ErrorLoadData';

function App() {
    const { t } = useTranslation()
    const params = useParams()
    const { query } = params
    const getPostsByQuery: any = GetSearchResultsQuery({ query }, { enabled: !Boolean(query === '') })

    if (getPostsByQuery.isError)
        return <ErrorLoadData />

    if (getPostsByQuery?.isLoading)
        return <div className='flex items-center justify-center p-4 h-60'><Loader size={44} /></div>

    if (getPostsByQuery?.data?.length === 0)
        return <div className='flex items-center justify-center p-4 text-lg font-bold h-60'>{t("There are no results to display")}</div>
    return (
        <>
            <Helmet>
                <title>{t("Search results")} | {query} | CAFYB</title>
            </Helmet>
            {getPostsByQuery && <div className='p-4'>
                <HeaderTitle
                    title={"Search results"} />

                <ul className='pl-7'>
                    {getPostsByQuery?.data?.map((item) => (
                        <li className='text-lg font-medium border-b border-b-slate-200 last:border-r-0' key={item.id}>
                            <Link to={`/${item.slug}`} className='flex p-4 transition-all duration-300 hover:text-slate-950 dark:hover:text-slate-50 hover:pl-7'>
                                {item?.title?.rendered}
                            </Link>
                        </li>
                    ))}
                </ul>
                {getPostsByQuery?.data?.length === 0 && <div className='flex items-center justify-center text-lg h-60 opacity-70'>{t("There are no results to display")}</div>}
            </div>}
        </>
    )
}

export default App