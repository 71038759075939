import HeaderTitle from '@/components/HeaderTitle'
import { GetPostDetailsQuery } from '@/query/posts'
import { Anchor, Breadcrumbs, Loader } from '@mantine/core'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import ErrorLoadData from '@/components/ErrorLoadData';
function App() {
    const params = useParams()
    const { slug } = params
    const { t } = useTranslation()
    const getPost: any = GetPostDetailsQuery({ slug: slug })

    const items = !getPost.isLoading && [
        {
            id: 1,
            isCate: true,
            title: getPost?.data[0]?._embedded["wp:term"][0][0]?.name,
            href: `/category/${getPost?.data[0]?._embedded["wp:term"][0][0]?.slug}`,
            icon: 'solar:tag-line-duotone'
        },
        {
            id: 2,
            isCate: false,
            title: getPost?.data[0]?.title?.rendered,
            href: `/${getPost?.data[0]?.slug}`,
            icon: 'solar:file-text-broken'
        },
    ].map((item, index) => (
        <Anchor className={`${item.isCate ? 'font-semibold' : ''} flex items-center gap-2`} href={item.href} key={index}>
            <Icon className='w-5 h-5' icon={item.icon} />{item.title}
        </Anchor>
    ));
    if (getPost.isError)
        return <ErrorLoadData />
    if (getPost.isLoading)
        return <div className='flex items-center justify-center p-4 h-60'><Loader size={44} /></div>

    if (getPost.isError)
        return <div>Error</div>
    if (getPost?.data?.length === 0)
        return <div className='flex items-center justify-center p-4 text-lg font-bold h-60'>{t("Content not found")}</div>
    return (
        <>
            <Helmet>
                <title>{getPost?.data?.length === 0 ? t("Content not found") : getPost?.data[0]?.title?.rendered} | CAFYB</title>
            </Helmet>
            <div className='p-4'>
                <HeaderTitle
                    title={getPost?.data[0]?.title?.rendered}
                    children={<Breadcrumbs classNames={{ breadcrumb: "text-sm text-slate-700 dark:text-slate-100", root: "max-sm:flex-wrap" }}>{items}</Breadcrumbs>} />

                <div className='p-2 px-4 content-help-center' dangerouslySetInnerHTML={{ __html: getPost?.data[0]?.content?.rendered }} />
                <div className='flex justify-between p-2 py-6 border-t-2 border-t-slate-200 dark:border-t-slate-700'>

                    <AnimatePresence>
                        {getPost?.data[0]?.previous && <motion.div
                            initial={{ y: -60, opacity: 0, rotate: 10 }}
                            exit={{ y: -60, opacity: 0, rotate: 10 }}
                            animate={{ y: 0, opacity: 1, rotate: 0 }}
                        >
                            <Link className='flex items-center gap-2 p-2 px-6 text-sm text-white bg-red-600 rounded-md' to={`/${getPost?.data[0]?.previous?.slug}`}>
                                <Icon className='w-4 h-4' icon={`fa:arrow-left`} />
                                {t("Previous Article")}
                            </Link>
                        </motion.div>
                        }
                    </AnimatePresence>
                    <AnimatePresence>

                        {getPost?.data[0]?.next &&
                            <motion.div initial={{ y: -60, opacity: 0, rotate: 10 }}
                                exit={{ y: -60, opacity: 0, rotate: 10 }}
                                animate={{ y: 0, opacity: 1, rotate: 0 }}
                            >
                                <Link className='flex items-center gap-2 p-2 px-6 text-sm text-white bg-red-600 rounded-md' to={`/${getPost?.data[0]?.next?.slug}`}>
                                    {t("Next Article")}
                                    <Icon className='w-4 h-4' icon={`fa:arrow-right`} />
                                </Link>
                            </motion.div>
                        }
                    </AnimatePresence>
                </div>
            </div>
        </>
    )
}

export default App