import { ReactNode, useState } from "react"
import Sidebar from "./Sidebar"
import Navbar from "./Navbar"
import { useTranslation } from "react-i18next"
import { useHotkeys } from "@mantine/hooks"
import { Outlet, useLocation } from "react-router-dom"
import IndexComponent from "../IndexComponent"
import Footer from "./Footer"

function DocsLayout({ children }: { children: ReactNode }) {
    const [isShow, setIsShow] = useState(false)
    const { i18n, t } = useTranslation()
    const lang = i18n.language
    const location = useLocation()

    const [pinned, setPinned] = useState(true)
    const [page, setPage] = useState(1)
    const [clickableItem, setClickableItem] = useState('')
    useHotkeys([
        ['ctrl+b', () => setPinned(!pinned)],
    ]);
    return (
        <>
            {location.pathname == '/' ? <IndexComponent /> :
                <div dir={lang == "ar" ? "rtl" : "ltr"} className="pt-16 bg-white dark:bg-slate-900">
                    <Navbar
                        clickableItem={clickableItem}
                        setClickableItem={setClickableItem}
                        pinned={pinned}
                        setPinned={setPinned}
                        isShow={isShow}
                        setIsShow={setIsShow}
                    />
                    <div className={`flex max-sm:flex-col`}>
                        <div className={`${pinned ? 'w-[320px]' : 'w-0'} ${pinned ? 'translate-x-0' : '-translate-x-[320px]'} transition-all  max-sm:w-full`}>
                            <Sidebar
                                clickableItem={clickableItem}
                                setClickableItem={setClickableItem}
                                setPinned={setPinned}
                                pinned={pinned}
                                page={page}
                            />
                        </div>
                        <div className={`${!pinned ? 'w-full' : 'w-[calc(100%-320px)]'} min-h-[90vh] max-sm:w-full`}>
                            {children}
                            <Footer />
                        </div>
                    </div>
                </div>
            }
            <Outlet />
        </>
    )
}

export default DocsLayout